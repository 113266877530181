import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { alpha, createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import componentsOverride from '../theme/overrides';
import palette from '../theme/palette';

ThemePrimaryColor.propTypes = {
  children: PropTypes.node
};

export default function ThemePrimaryColor({ children }) {
  const defaultTheme = useTheme();
  const colorPalette = useMemo(
    () => ({
      name: 'default',
      ...palette.light.primary
    }),
    []
  );

  const theme = createTheme(
    useMemo(
      () => ({
        ...defaultTheme,
        palette: {
          ...defaultTheme.palette,
          primary: colorPalette
        },
        customShadows: {
          ...defaultTheme.customShadows,
          primary: `0 8px 16px 0 ${alpha(colorPalette.main, 0.24)}`
        }
      }),
      [colorPalette, defaultTheme]
    )
  );
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
