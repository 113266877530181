import Router from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import ScrollToTop from './components/ScrollToTop';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import usePageTracking from './hook/usePageTracking';

export default function App() {
  usePageTracking();
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <div>
          <GlobalStyles />
          <ScrollToTop />
          <Router />
        </div>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
