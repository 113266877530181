import { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

import MainLayout from '../layouts/main';

const RoutedPage = (Component) => (props) =>
  (
    <Suspense fallback={<div style={{ minHeight: '100vh' }} />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [{ element: <LandingPage /> }]
    },
    {
      path: '/privacy',
      element: <MainLayout />,
      children: [{ element: <PrivacyPage /> }]
    },
    { path: '*', element: <NotFound /> }
  ]);
}

const LandingPage = RoutedPage(lazy(() => import('../pages/LandingPage')));
const PrivacyPage = RoutedPage(lazy(() => import('../pages/PrivacyPage')));
const NotFound = RoutedPage(lazy(() => import('../pages/Page404')));
