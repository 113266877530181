import { Outlet, Link as RouterLink } from 'react-router-dom';
import { Box, Container, Link, Typography } from '@mui/material';

export default function MainLayout() {
  return (
    <>
      <div>
        <Outlet />
      </div>

      <Box
        sx={{
          py: 5,
          textAlign: 'center',
          position: 'relative',
          bgcolor: 'background.default'
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="caption" component="p" paddingBottom={4}>
            <RouterLink to="/privacy" style={{ textDecoration: 'none' }}>
              Privacy policy
            </RouterLink>
          </Typography>

          <Typography variant="caption" component="p">
            © 2022, all rights reserved
            <br />
            <Link href="https://getvarm.app/">getvarm.app</Link>
          </Typography>
        </Container>
      </Box>
    </>
  );
}
